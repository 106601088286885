

export const SET_INCLUDE_PUBLIC = 'SET_INCLUDE_PUBLIC';
export const SET_SEARCH_KEYS = 'SET_SEARCH_KEYS';
export const SET_HITS = 'SET_HITS';

export const FETCH_REVIEWING_NOTES = 'FETCH_REVIEWING_NOTES';



