import React, {useRef} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; 

export default function ImageEditorCropper({setImage, imagePreview}) {
    const cropper = useRef(null);
    function cropImage() {
        if (typeof cropper.current.getCroppedCanvas() === 'undefined') {
            return;
        }
        cropper.current.getCroppedCanvas().toBlob(blob => {
            setImage(blob);
        }, 'image/jpeg');
    }

    return (
      <Cropper
        ref={cropper}
        src={imagePreview}
        style={{height: 300, width: '100%', color: "red"}}
        // Cropper.js options
        initialAspectRatio={1}
        preview=".img-preview"
        viewMode={1}
        guides={false}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={true}
        responsive={true}
        autoCropArea={1}
        checkOrientation={true} 
        scalable={false}
        rotatable={false}
        movable={false}
        crop={cropImage} />
    );
}