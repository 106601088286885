import React from 'react';
import TopicListItem from './TopicListItem';
import { Menu, Header, Segment, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function TopicList({
  topics,
  getNextTopics,
  loading,
  moreTopics,
  selectedTopic
}) {

  const { filter, filterFolder } = useSelector((state) => state.topic);

  return (
    <>
      {topics && (
        <>
          <Menu vertical size='large' style={{ width: '100%', flex: "1 1 auto", height: 40, overflowY: 'auto', marginBottom: 0, marginTop: 0, fontSize: 14}} className="topicMenu titleTextFont">


            {topics.map((topic) => (

             (filter == "all" || (filter == "shared" && topic.shared_id) || (filter === "folder" && filterFolder && topic.folders && topic.folders.includes(filterFolder))) &&
              <TopicListItem topic={topic} key={topic.id} active={selectedTopic && selectedTopic.id == topic.id} />

                
                 
                 ))}
        </Menu>

        </>
      )}
    </>
  );
}
