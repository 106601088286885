import {
  SET_INCLUDE_PUBLIC,
  SET_SEARCH_KEYS,
  SET_HITS

} from './searchConstants';
import { dataFromSnapshot, fetchNotesFromFirestore } from '../../app/firestore/firestoreService';
import { asyncActionStart, asyncActionFinish, asyncActionError } from '../../app/async/asyncReducer';


export function setIncludePublic(value) {
  return {
    type: SET_INCLUDE_PUBLIC,
    payload: value,
  };
}

export function setSearchKeys(userKey, sharingKey) {
  return {
    type: SET_SEARCH_KEYS,
    payload: { userKey, sharingKey },
  };
}

export function setHits(hits) {
  return {
    type: SET_HITS,
    payload: hits,
  };
}



