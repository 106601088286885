export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const FETCH_NOTES = 'FETCH_NOTES';
export const LISTEN_TO_NOTE_CHAT = 'LISTEN_TO_NOTE_CHAT';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
export const LISTEN_TO_SELECTED_NOTE = 'LISTEN_TO_SELECTED_NOTE';
export const CLEAR_SELECTED_NOTE = 'CLEAR_SELECTED_NOTE';
export const CLEAR_NOTES = 'CLEAR_NOTES';
export const SET_FILTER = 'SET_FILTER';
export const SET_START_DATE = 'SET_START_DATE';
export const RETAIN_STATE = 'RETAIN_STATE';
export const SET_NOTES = 'SET_NOTES';
