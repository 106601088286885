const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR,
  };
}

const initialState = {
    sidebarVisible: window.innerWidth > 767,

  };

export default function sidebarReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_SIDEBAR:
        return {
            sidebarVisible: true,
          };
    case CLOSE_SIDEBAR:
        return {
            sidebarVisible: false,
          };
    default:
      return state;
  }
}
