import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromUser } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { logEvent} from '../../app/common/logging/logging'

export default function FolderRow({ topicContext, uid, folder, topicFolders, clickHandler, topicsInFolder, filterDropdownOpen, setFilterDropdownOpen }) {

    const [confirmOpen, setConfirmOpen] = useState(false);

    const [popupOpen, setPopupOpen] = useState(false);

    const dispatch = useDispatch();

    function handleOpen() {
        setPopupOpen(true);
    }

    function handleClose() {
        setPopupOpen(false);
    }

    function renameFolder() {

        setPopupOpen(false);

        if (topicContext) {

            dispatch(closeModal());
        } else {
            setFilterDropdownOpen(false);

        }
        dispatch(openModal({ modalType: 'RenameFolderModal', modalProps: { topicContext: topicContext, oldFolderToRename: folder, topicsInFolder, setFilterDropdownOpen: setFilterDropdownOpen } }))

        logEvent("Rename Folder Tapped");

    }


    async function handleDelete() {
        setPopupOpen(false);
        setConfirmOpen(true)
    }

    async function deleteConfirmed() {
        removeFolderFromUser(uid, folder, topicsInFolder);
        setConfirmOpen(false)
        logEvent("Deleted Folder");
        

    }

    async function popupClicked(event) {
        setPopupOpen(false);

        event.preventDefault();
        event.stopPropagation();

    }


    async function popupButtonClicked(event) {

        setPopupOpen(!popupOpen);

        event.preventDefault();
        event.stopPropagation();

    }



    return (

        <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", paddingLeft: 16, paddingRight: 0, height: 36 }}
            className={"hoverableMenuItem"}
            onClick={clickHandler} >
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <span style={{ maxWidth: 160, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}> {folder}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {(topicContext && topicFolders.includes(folder)) &&
                    <div style={{ marginRight: 14, display: "flex", justifyContent: "center", flexDirection: "column" }}>

                        <img
                            src='/assets/folderCheck.png'
                            alt='logo'
                            style={{ width: "auto", height: 14 }}
                        /></div>}
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>

                    <Popup
                        position='left center'
                        wide
                        open={popupOpen}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onClick={popupClicked}

                        trigger={
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>

                                <Button
                                    onClick={popupButtonClicked}

                                    circular
                                    style={{
                                        backgroundColor: "rgba(0,0,0,.0)",
                                        height: 20,
                                        width: 40,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: 0

                                    }}
                                >
                                    <img
                                        src='/assets/dots.png'
                                        alt='logo'
                                        style={{ width: 20, height: "auto" }}
                                    />
                                </Button>
                            </div>
                        }
                        on='click'
                    >
                        <Grid divided columns='equal' style={{ fontSize: 10, padding: 0 }}>

                            <Grid.Column style={{ padding: 0 }}>
                                <Button
                                    onClick={renameFolder}
                                    style={{
                                        color: "#3F3F3F",
                                        backgroundColor: "#FFF",
                                        textAlign: "center",
                                        width: "100%",
                                        lineHeight: 1.1,
                                        padding: 12
                                    }}
                                    className=''
                                >
                                    Rename
                                </Button>
                            </Grid.Column>
                            <Grid.Column
                                style={{ padding: 0, display: "flex", alignItems: "center" }}
                            >
                                <Button
                                    onClick={handleDelete}
                                    style={{
                                        color: "#3F3F3F",
                                        backgroundColor: "#FFF",
                                        textAlign: "center",
                                        width: "100%",
                                        lineHeight: 1.1,
                                        padding: 12

                                    }}
                                    className=''
                                >
                                    Delete
                  </Button>
                            </Grid.Column>
                        </Grid>
                    </Popup>
                    <Confirm
                        header='Delete folder'

                        content={
                            "Are you sure you want to delete this folder? Note that this will only delete the folder - not the topics inside the folder."
                        }
                        confirmButton={"Delete folder"}
                        open={confirmOpen}
                        onCancel={() => setConfirmOpen(false)}
                        onConfirm={deleteConfirmed}
                    />
                </div>
            </div>

        </div>
    );
}
