import {
    CREATE_TOPIC,
    UPDATE_TOPIC,
    DELETE_TOPIC,
    FETCH_TOPICS,
    LISTEN_TO_TOPIC_CHAT,
    LISTEN_TO_SELECTED_TOPIC,
    CLEAR_TOPICS,
    CLEAR_SELECTED_TOPIC,
    SET_FILTER,
    CLEAR_JUST_REMOVED,
    SET_NEEDS_TOPIC_FETCH,
  } from './topicConstants';
  import {
    asyncActionStart,
    asyncActionFinish,
    asyncActionError,
  } from '../../app/async/asyncReducer';
  import {
    fetchTopicsFromFirestore,
    dataFromSnapshot,
  } from '../../app/firestore/firestoreService';
  
  export function fetchTopics(selectedTopicId) {
    return async function (dispatch) {
      dispatch(asyncActionStart());
      try {
        const snapshot = await fetchTopicsFromFirestore().get();


        const topics = snapshot.docs.map((doc) => dataFromSnapshot(doc));

        dispatch({ type: FETCH_TOPICS, payload: { topics: topics} });
        dispatch(asyncActionFinish());
      } catch (error) {
        dispatch(asyncActionError(error));
      }
    };
  }
  
  export function setNeedsTopicFetch(value) {

    return {
      type: SET_NEEDS_TOPIC_FETCH,
      payload: value,
    };
  }

  export function setFilter(filter, filterFolder) {

    return {
      type: SET_FILTER,
      payload: {filter: filter, filterFolder: filterFolder},
    };
  }

  export function listenToSelectedTopic(topic) {

    return {
      type: LISTEN_TO_SELECTED_TOPIC,
      payload: topic,
    };
  }
  
  export function clearJustRemovedTopic() {
    return {
      type: CLEAR_JUST_REMOVED
    }
  }

  export function clearSelectedTopic() {
    return {
      type: CLEAR_SELECTED_TOPIC
    }
  }
  
  export function createTopic(topic) {
    return {
      type: CREATE_TOPIC,
      payload: topic,
    };
  }
  
  export function updateTopic(topic) {
    return {
      type: UPDATE_TOPIC,
      payload: topic,
    };
  }
  
  export function deleteTopic(topicId) {
    return {
      type: DELETE_TOPIC,
      payload: topicId,
    };
  }
  
  export function listenToTopicChat(comments) {
    return {
      type: LISTEN_TO_TOPIC_CHAT,
      payload: comments,
    };
  }
  
  export function clearTopics() {
    return {
      type: CLEAR_TOPICS,
    };
  }
  