import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import EventList from './EventList';
import { useSelector, useDispatch } from 'react-redux';
import EventListItemPlaceholder from './EventListItemPlaceholder';
import EventFilters from './EventFilters';
import { fetchEvents } from '../eventActions';
import { useState } from 'react';
import EventsFeed from './EventsFeed';
import { useEffect } from 'react';
import { RETAIN_STATE } from '../eventConstants';
import TopicList from '../../topics/TopicList';
import { fetchTopics, listenToSelectedTopic } from '../../topics/topicActions';
import { fetchNotes, clearNotes } from '../../notes/noteActions';
import NoteList from '../../notes/NoteList';
import { listenToTopicFromFirestore, fetchNotesFromFirestore } from '../../../app/firestore/firestoreService';
import useFirestoreDoc, { useFirestoreDocs } from '../../../app/hooks/useFirestoreDoc';
import EditorWrapper from '../../editor/EditorWrapper';
import { FETCH_NOTES } from '../../notes/noteConstants';

export default function EventDashboard({ match }) {
  const limit = 200;
  const dispatch = useDispatch();
  const { events, moreEvents, filter, startDate, lastVisible, retainState } = useSelector((state) => state.event);
  const { topics, moreTopics, selectedTopic } = useSelector((state) => state.topic);
  const { notes, moreNotes, notesStartDate, notesLastVisible } = useSelector((state) => state.note);

  const { loading } = useSelector((state) => state.async);
  const { authenticated } = useSelector((state) => state.auth);
  const [loadingInitial, setLoadingInitial] = useState(false);

  useFirestoreDoc({
    shouldExecute: typeof match.params.id !== 'undefined',
    query: () => listenToTopicFromFirestore(match.params.id),
    data: (topic) => dispatch(listenToSelectedTopic(topic)),
    deps: [match.params.id, dispatch],
  });

  useFirestoreDocs({
    shouldExecute: typeof match.params.id !== 'undefined' && selectedTopic,
    query: () => fetchNotesFromFirestore(selectedTopic, 200, null),
    data: (snapshotNotes) => dispatch({ type: FETCH_NOTES, payload: { notes: snapshotNotes } }),
    deps: [match.params.id, dispatch, selectedTopic],
  });  



  useEffect(() => {
    // setLoadingInitial(true);

    return;
    if (selectedTopic) {

      dispatch(fetchNotes(selectedTopic, notesStartDate, limit, notesLastVisible)).then(() => {
        //setLoadingInitial(false);
      });
    }

    return () => {
      //dispatch({type: RETAIN_STATE})
    }
  }, [dispatch, selectedTopic]);

  function handleFetchNextEvents() {
    dispatch(fetchEvents(filter, startDate, limit, lastVisible));
  }

  function handleFetchNextTopics() {
    dispatch(fetchTopics(filter, startDate, limit, lastVisible));
  }

  function handleFetchNextNotes() {
    if (selectedTopic) {

      dispatch(fetchNotes(selectedTopic, notesStartDate, limit, notesLastVisible));
    }
  }

  return (

    <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "flex-end", height: "100%", minHeight: "100%" }}>
      {loadingInitial && (
        <>
          <EventListItemPlaceholder />
          <EventListItemPlaceholder />
        </>
      )}

      <div style={{ backgroundColor: "", flex: "1 0 auto", paddingTop: 60, paddingBottom: 0 }}>
        <div style={{ backgroundColor: "", maxHeight: "100vh", height: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", position: "relative", overflow: "" }}>

          <div style={{ backgroundColor: "", height: "100%", width: "100%", position: "absolute", top: 0, right: 0, display: "flex", flexDirection: "column-reverse", overflow: "auto", marginLeft: 50, paddingLeft: 20, paddingRight: 20 }}>
            <NoteList
              notes={notes}
              getNextNotes={handleFetchNextNotes}
              loading={loading}
              moreNotes={moreNotes}
              isHowTo={selectedTopic && selectedTopic.how_to == true}
            />
          </div>

        </div>
      </div>

      <EditorWrapper style={{ flexShrink: 0 }}>

      </EditorWrapper>
    </div>

  );
}
