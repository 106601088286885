import {
  SET_AUTOFLASHCARDS,
  SET_REVIEW_TYPE,
  SET_RANDOM_ORDER,
  SET_NUMBER_OF_NOTES,
  FETCH_REVIEWING_NOTES,
  UPDATE_SELECTED_NOTE,
  UNDO_GRADE,
  SET_SHOW_CURRENT_ANSWER,
  SET_STAGE,
  SET_CURRENT_NOTE_INDEX,
  SET_SELECTED_NOTES,
  SET_REVIEW_INITIAL_STATE,
  FLIP_CARD,
  NEXT_CARD,
  PREVIOUS_CARD,
  RESTART_REVIEW_AGAIN,
  RESTART_REVIEW_FORGOTTEN
} from './reviewConstants';
import {  getOverdueNotes, isNoteExcluded } from '../../app/common/util/util';

const initialState = {
  autoflashcardsEnabled: false,
  reviewType: "custom",
  randomOrder: false,
  numberOfNotes: null,
  notes: [],
  suggestedNotes: [],
  selectedNotes: [],

  currentNoteIndex: 0,
  showCurrentAnswer: false,
  stage: "configuring",
  indexOfLastViewedCardForUndo: 0
};

export default function reviewReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_REVIEW_INITIAL_STATE:
      return {
        ...initialState,
      };
    case SET_AUTOFLASHCARDS:
      return {
        ...state,
        autoflashcardsEnabled: payload,
        // Reset the card so you can see that it worked.
        showCurrentAnswer: false,
      };
    case SET_REVIEW_TYPE:
      return {
        ...state,
        reviewType: payload,
        numberOfNotes: (payload != "suggested" ? state.notes.length : state.suggestedNotes.length)

      };
    case SET_RANDOM_ORDER:
      return {
        ...state,
        randomOrder: payload,
      };
    case SET_NUMBER_OF_NOTES:
      return {
        ...state,
        numberOfNotes: payload,
      };
    case SET_SHOW_CURRENT_ANSWER:
      return {
        ...state,
        showCurrentAnswer: payload,
      };
    case FETCH_REVIEWING_NOTES:
      
      let includedNotes =  payload.notes.filter((note) => !isNoteExcluded(payload.topic, note.id))

      // Overdue notes are already filtered for excluded.
      let overdueNotes = getOverdueNotes(payload.topic, [...payload.notes]);

      return {
        ...state,
        notes: includedNotes,
        suggestedNotes: overdueNotes,
        numberOfNotes: (state.reviewType != "suggested" ? includedNotes.length : overdueNotes.length)
      };
    case UPDATE_SELECTED_NOTE:
      let oldSelectedNotes = state.selectedNotes
      const index = oldSelectedNotes.findIndex((note) => note.id === payload.id);
      oldSelectedNotes[index] = payload
      return {
        ...state,
        selectedNotes: oldSelectedNotes,
      };
    case UNDO_GRADE:
      return {
        ...state,
        notes: payload,
      };
    case SET_STAGE:
      return {
        ...state,
        stage: payload,
      };
    case SET_CURRENT_NOTE_INDEX:
      return {
        ...state,
        currentNoteIndex: payload,
        indexOfLastViewedCardForUndo: state.currentNoteIndex,
        showCurrentAnswer: false,

      };
    case SET_SELECTED_NOTES:
      return {
        ...state,
        selectedNotes: payload,
      };
    case FLIP_CARD:
      return {
        ...state,
        showCurrentAnswer: !state.showCurrentAnswer,
      };
    case NEXT_CARD:

      let currentNote = state.selectedNotes[state.currentNoteIndex]
      currentNote.recalled_during_review = payload
      state.selectedNotes[state.currentNoteIndex] = currentNote

      if (state.currentNoteIndex + 1 >= state.selectedNotes.length) {
        return {
          ...state,
          stage: "complete",

        };
      } else {
        return {
          ...state,
          currentNoteIndex: state.currentNoteIndex + 1,
          indexOfLastViewedCardForUndo: state.currentNoteIndex,
          showCurrentAnswer: false,

        };
      }


    case PREVIOUS_CARD:

      let oldNote = state.selectedNotes[state.currentNoteIndex - 1]
      oldNote.recalled_during_review = null
      state[state.currentNoteIndex - 1] = oldNote
      return {
        ...state,
        currentNoteIndex: state.currentNoteIndex - 1,
        indexOfLastViewedCardForUndo: state.currentNoteIndex,

        showCurrentAnswer: false,


      };

    case RESTART_REVIEW_AGAIN:

      for (var i = 0; i < state.selectedNotes.length; i++) {
        state.selectedNotes[i].recalled_during_review = null
      }

      return {
        ...state,
        currentNoteIndex: 0,
        showCurrentAnswer: false,
        stage: "reviewing",
        indexOfLastViewedCardForUndo: 0,

      };

    case RESTART_REVIEW_FORGOTTEN:

      var forgottenNotes = []
      for (var i = 0; i < state.selectedNotes.length; i++) {
        if (state.selectedNotes[i].recalled_during_review == false) {
          state.selectedNotes[i].recalled_during_review = null
          forgottenNotes.push(state.selectedNotes[i])
        }
      }

      return {
        ...state,
        currentNoteIndex: 0,
        showCurrentAnswer: false,
        stage: "reviewing",
        indexOfLastViewedCardForUndo: 0,
        selectedNotes: forgottenNotes

      };




    default:
      return state;
  }
}
