export const CREATE_TOPIC = 'CREATE_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const FETCH_TOPICS = 'FETCH_TOPICS';
export const LISTEN_TO_TOPIC_CHAT = 'LISTEN_TO_TOPIC_CHAT';
export const LISTEN_TO_SELECTED_TOPIC = 'LISTEN_TO_SELECTED_TOPIC';
export const CLEAR_SELECTED_TOPIC = 'CLEAR_SELECTED_TOPIC';
export const CLEAR_TOPICS = 'CLEAR_TOPICS';
export const RETAIN_STATE_TOPIC = 'RETAIN_STATE';
export const SET_FILTER = 'SET_FILTER';
export const CLEAR_JUST_REMOVED = 'CLEAR_JUST_REMOVED';
export const SET_NEEDS_TOPIC_FETCH = 'SET_NEEDS_TOPIC_FETCH';



