import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { updateUserEmail } from '../../app/firestore/firebaseService';
import firebase from '../../app/config/firebase';
import { addFolderToUser, addFolderToTopic, addTopicToFirestore, renameTopic } from '../../app/firestore/firestoreService';
import { setFilter } from '../topics/topicActions';
import { logEvent } from '../../app/common/logging/logging';

export default function RenameTopicModal({topic}) {
    const dispatch = useDispatch();

    return (
        <ModalWrapper size='mini' header="Enter the topic's new name">
            <Formik
                initialValues={{ topicName: topic.name }}
                validationSchema={Yup.object({
                    topicName: Yup.string().required()
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await renameTopic(topic.id, values.topicName);

                         dispatch(closeModal());

                         logEvent("Renamed Topic");

                    } catch (error) {

                        setErrors({ auth: error.message });

                        setSubmitting(false);
                    }
                }}
            >

                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className='ui form'>
                        <MyTextInput autoFocus name='topicName' placeholder={""} />
                        {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                        <Button className="submitButton"
                            style={{ color: "white", height: 44, borderRadius: 22 }}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Create'
                        />

                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    )
}