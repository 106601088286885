import React from 'react';
import { Menu, Container} from 'semantic-ui-react';
import {  useHistory } from 'react-router-dom';


export default function SpecialPageNavBar({  }) {

   const history = useHistory();


  return (
    <Menu inverted fixed='top' style={{ height: 60, paddingLeft: 16, paddingRight: 16 }} className="searchNavMenu">

      <Container style={{fontSize: 16, display: "flex", justifyContent: "space-between"}}>

        <Menu.Item onClick={() => history.push("/")} 
           style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 0, paddingLeft: 0, borderLeft: 0, width: 100 , marginRight: 0}}>
          <img src='/assets/logo.png' alt='logo' style={{ width: "auto", height: 40, paddingTop: 8, paddingBottom: 8, paddingRight: 10 }} />

          <span className="titleTextFont" style={{fontSize: 20}}>NOTEFUEL</span>

        </Menu.Item>

      </Container>
    </Menu>
  );
}



