import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { updateUserEmail } from '../../app/firestore/firebaseService';
import firebase from '../../app/config/firebase';
import { addFolderToUser, addFolderToTopic } from '../../app/firestore/firestoreService';
import { setFilter } from '../topics/topicActions';

export default function CreateFolderModal({topicContext}) {
    const dispatch = useDispatch();

    const { currentUserProfile } = useSelector((state) => state.profile);
    const { selectedTopic } = useSelector((state) => state.topic);

    return (
        <ModalWrapper size='mini' header="Enter the new folder's name">
            <Formik
                initialValues={{ folderName: '' }}
                validationSchema={Yup.object({
                    folderName: Yup.string().required()
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await addFolderToUser(currentUserProfile.id, values.folderName);

                        if (topicContext) {
                            await addFolderToTopic(selectedTopic.id, values.folderName);
                            dispatch(closeModal());
                            dispatch(openModal({ modalType: 'AddToFoldersModal', modalProps: { topicContext: true } }))
                        } else {
                            dispatch(closeModal());
                            dispatch(setFilter( "folder", values.folderName));
                        }


                    } catch (error) {

                        setErrors({ auth: error.message });

                        setSubmitting(false);
                    }
                }}
            >

                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className='ui form'>
                        <MyTextInput autoFocus name='folderName' placeholder={""} />
                        {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                        <Button className="submitButton"
                            style={{ color: "white", height: 44, borderRadius: 22 }}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Save'
                        />

                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    )
}