import React, { useState } from "react";
import { Menu, Image, Dropdown, Confirm } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { signOutFirebase } from '../../app/firestore/firebaseService';
import ReviewHistoryGraph from '../review/ReviewHistoryGraph';
import { getTopicStats, getNextReviewDateString } from '../../app/common/util/util'
import { openModal } from '../../app/common/modals/modalReducer';
import { deleteTopicInFirestore } from "../../app/firestore/firestoreService";
import { logEvent } from "../../app/common/logging/logging";

export default function TopicTitle() {


  const dispatch = useDispatch()

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { selectedTopic, topics } = useSelector((state) => state.topic);
  const history = useHistory();

  async function handleSignOut() {
    try {
      history.push('/');
      await signOutFirebase();
    } catch (error) {
      toast.error(error.message);
    }
  }

  const { uniqueDates, notesReviewed } = getTopicStats(selectedTopic)
  const nextReviewDate = getNextReviewDateString(selectedTopic)

  let visibilityString
  if (selectedTopic) {
    if (selectedTopic.visibility == "public") {
      visibilityString = "Public"
    } else if (selectedTopic.visibility == "with_link") {
      visibilityString = "With link"

    } else {
      visibilityString = "Just me"

    }
  } else {
    visibilityString = ""
  }

  const deleteLanguage = selectedTopic && selectedTopic.shared_id ? "Remove" : "Delete"
  const deleteButtonText = selectedTopic && selectedTopic.shared_id ? "Remove from my topics" : "Delete"

  async function handleDelete() {
    setDropdownOpen(false);

    setConfirmOpen(true)
    logEvent("Note List Delete Topic Tapped")
  }

  async function deleteConfirmed() {
     deleteTopicInFirestore(selectedTopic.id);
      setDropdownOpen(false);
     toast.info("Topic has been deleted.")
     setConfirmOpen(false)
     //history.push("/")
     logEvent("Note List Delete Topic Confirmed")

  }

  return (
    selectedTopic &&
    <>


      <Menu.Item id="titleNavBarMenuItem" style={{ border: 0, padding: 0, flexGrow: 3, width: 60}}   >



        <Dropdown className="navBarTitle titleTextFont " open={dropdownOpen} onOpen={() => {
          setDropdownOpen(true);
        
          logEvent("Note List Topic Tapped")
      }} onClose={() => setDropdownOpen(false)}
        pointing='top left' text={selectedTopic.name }
        style={{ fontSize: 15, color: "#3F3F3F", display: "flex" , width: "100%"  }}>
          <Dropdown.Menu className="normalTextFont topicDetailsDropdownMenu" style={{ fontSize: 14, color: "#3F3F3F", width: "80%", maxWidth: 350, marginLeft: 0 }}>
            {/* <Dropdown.Item
            as={Link}
            to='/createEvent'
            text='Create Event'
            icon='plus'
          />
          <Dropdown.Item as={Link} to='/account' text='My account' icon='settings' />
          <Dropdown.Item
            text='Sign out'
            icon='power'
          /> */}




            <div className="topicStatsWrapper" style={{ width: "100%", padding: 16 }}>
              <div className="topicStatsList" style={{ position: "relative", width: "100%" }}>

                <div className="topicStat normalTextFont">
                  <div className="topicStatKey">Notes reviewed</div>
                  <div className="topicStatValue titleTextFont">{notesReviewed ?? "0"}</div>
                </div>
                <div className="topicStat">
                  <div className="topicStatKey">Days reviewed</div>
                  <div className="topicStatValue titleTextFont">{uniqueDates ?? "0"}</div>
                </div>
                <div className="topicStat">
                  <div className="topicStatKey">Next review</div>
                  <div className="topicStatValue titleTextFont">{nextReviewDate}</div>
                </div>

                <ReviewHistoryGraph postReview={false} ></ReviewHistoryGraph>

              </div>

            </div>

            <Dropdown.Divider />
            <Dropdown.Item description={selectedTopic && selectedTopic.auto_spaced_repetition ? "On" : "Off"} text='Spaced repetition'
              onClick={() => {dispatch(openModal({ modalType: 'SpacedRepetitionModal', modalProps: {} }));
              logEvent("Topic Info SR Tapped")
            
            }

              } />

            {(selectedTopic && !selectedTopic.shared_id) &&
              <>
                <Dropdown.Item description={visibilityString} text='Visible to'
                  onClick={() => {
                    dispatch(openModal({ modalType: 'VisibilityModal', modalProps: {} }));
                    logEvent("Topic Info Visibility Tapped")
                
                  }} />

                <Dropdown.Item text='Rename' onClick={() => {
                  dispatch(openModal({ modalType: 'RenameTopicModal', modalProps: { topic: selectedTopic } }));
                  logEvent("Note List Rename Topic Tapped")
                
              }} />
              </>
            }
            {/* <Dropdown.Item text='Move notes' /> */}

            <Dropdown.Item text='Add to folder'
              onClick={() => dispatch(openModal({ modalType: 'AddToFoldersModal', modalProps: { topicContext: true } }))} />
            <Dropdown.Item text={deleteButtonText} onClick={handleDelete} />


          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>


      <Confirm
        header={deleteLanguage + ' topic'}

        content={
          "Are you sure you want to " + deleteLanguage.toLowerCase() + " this topic?"
        }
        confirmButton={deleteLanguage + " topic"}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deleteConfirmed}
      />
    </>
  );
}
