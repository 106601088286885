import React from 'react';
import NoteListItem from './NoteListItem';
import InfiniteScroll from 'react-infinite-scroller';

export default function NoteList({
  notes,
  getNextNotes,
  loading,
  moreNotes,
  isHowTo,
  excluded,
  topicId,
}) {
  return (
    <>
      {notes.length !== 0 && (

          notes.map((note) => (
            <NoteListItem note={note} key={note.id} isHowTo={isHowTo} excluded={excluded} isExcluded={excluded.includes(note.id)} topicId={topicId}/>
          ))

        // <InfiniteScroll
        //   pageStart={0}
        //   loadMore={getNextNotes}
        //   hasMore={!loading && moreNotes}
        //   initialLoad={false}
        // >
        //   {notes.map((note) => (
        //     <NoteListItem note={note} key={note.id} isHowTo={isHowTo} />
        //   ))}
        // </InfiniteScroll>
      )}
    </>
  );
}
