import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import NoteEditor from "./NoteEditor";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal } from "../../app/common/modals/modalReducer";

export default function EditorModal({ note, topicId }) {

  const dispatch = useDispatch();

  const { sidebarVisible } = useSelector((state) => state.sidebar);



  return (
    <ModalWrapper
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ padding: 0, display: "flex", alignItems: "center" }}>
            Edit note
          </div>

        </div>
      }
      className='editor'
      styles={{}}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 10px 30px 0 rgba(0,0,0,0.05)",
          fontSize: 14,
          borderRadius: 10,
        }}
      >
        <NoteEditor editingNote={note}></NoteEditor>
      </div>
    </ModalWrapper>
  );
}
