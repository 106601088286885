import React from 'react';
import { Grid, Loader, Sidebar, Menu, Segment, Dropdown, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { useState } from 'react';
import { useEffect } from 'react';
import { fetchTopics, setFilter } from '../topics/topicActions';
import TopicList from '../topics/TopicList';
import { RETAIN_STATE } from '../events/eventConstants';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signOutFirebase } from '../../app/firestore/firebaseService';
import ChangeEmailForm from '../auth/ChangeEmailForm';
import { openModal, closeModal } from '../../app/common/modals/modalReducer';
import firebase from '../../app/config/firebase';
import SubscribeWall from '../auth/SubscribeWall';
import { closeSidebar, openSidebar } from './sidebarReducer';
import { clearNotes } from '../notes/noteActions';
import FoldersList from '../folders/FoldersList';
import { logEvent } from '../../app/common/logging/logging';

export default function TopicFilterDropdown({ filterDropdownOpen, setFilterDropdownOpen }) {
    const { filter, filterFolder } = useSelector((state) => state.topic);

    const dispatch = useDispatch();

    async function addFolder() {

        dispatch(closeModal());
        dispatch(openModal({ modalType: 'CreateFolderModal', modalProps: { topicContext: false } }))
        logEvent("Add Folder Tapped")

    }


    var title = ""
    if (filter === "shared") {
        title = "Shared"
    } else if (filter == "folder" && filterFolder) {
        title = filterFolder
    } else {
        title = "Topics"
    }

    return (

        <Dropdown
            text={ title}
            pointing='top left'
            style={{ color: "#FFF", display: "flex" }}
            className={"topicFilterDropdown"}
        >

            <Dropdown.Menu open={ filterDropdownOpen} className="settingsDropdownMenu normalTextFont" style={{ marginLeft: -1, fontSize: 14, color: "#3F3F3F", width: 232, border: 0 }}>


                <div style={{ padding: 0, display: "flex", alignItems: "center", justifyContent: "center", fontSize: 16 }}>
                    <div className="titleTextFont" style={{ paddingTop: 16, paddingBottom: 9}}>
                        Filter topics

                  </div>
                </div>

                <Dropdown.Divider />

                <Dropdown.Item text='All topics' onClick={() => dispatch(setFilter("all"))} />
                <Dropdown.Item text='Shared with you'  onClick={() => dispatch(setFilter("shared"))} />

                <Dropdown.Divider />
                <div style={{ padding: 0, display: "flex", justifyContent:"space-between", fontSize: 18 }}>

                    <div style={{ paddingLeft: 16, display: "flex", alignItems: "center", fontSize: 12 }}>
                        FOLDERS
                    </div>
                    <div style={{
                        padding: 0, display: "flex", justifyContent: "flex-end", textAlign: "right"
                    }}>
                    <Button
                        onClick={addFolder}
                        circular
                        style={{
                            backgroundColor: "#FFF",
                            height: 30,
                            width: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 0

                        }}
                    >
                        <img
                            src='/assets/addTopic.png'
                            alt='logo'
                            style={{ width: "auto", height: 16 }}
                        />
                    </Button>
                    </div>

                </div>

                <Dropdown.Divider style={{marginBottom: 0}} />
                <FoldersList topicContext={false} filterDropdownOpen={filterDropdownOpen} setFilterDropdownOpen={setFilterDropdownOpen}></FoldersList>

            </Dropdown.Menu>
        </Dropdown>

    );
}
