import {combineReducers} from 'redux';
import testReducer from '../../features/sandbox/testReducer';
import eventReducer from '../../features/events/eventReducer';
import modalReducer from '../common/modals/modalReducer';
import authReducer from '../../features/auth/authReducer';
import asyncReducer from '../async/asyncReducer';
import profileReducer from '../../features/profiles/profileReducer';
import {connectRouter} from 'connected-react-router';
import topicReducer from '../../features/topics/topicReducer';
import noteReducer from '../../features/notes/noteReducer';
import sidebarReducer from '../../features/sidebar/sidebarReducer';
import reviewReducer from '../../features/review/reviewReducer';
import editorReducer from '../../features/editor/editorReducer';
import searchReducer from '../../features/search/searchReducer';
import onboardingReducer from '../../features/onboarding/onboardingReducer';

import { RESET_STORE } from './rootConstants';

const appReducer = history => combineReducers({
    router: connectRouter(history),
    test: testReducer,
    event: eventReducer,
    topic: topicReducer,
    note: noteReducer,
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    profile: profileReducer,
    sidebar: sidebarReducer,
    review: reviewReducer,
    editor: editorReducer,
    search: searchReducer,
    onboarding: onboardingReducer,
})

const rootReducer = history => (state, action) => {
    if (action.type === RESET_STORE) {

        const { onboarding } = state;
        state = { onboarding} ;
    }
    return appReducer(history)(state, action);
};

export default rootReducer;