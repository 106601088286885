import React, { useState } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import PhotoWidgetCropper from './PhotoWidgetCropper';
import cuid from 'cuid';
import { getFileExtension } from '../util/util';
import { uploadToFirebaseStorage } from '../../firestore/firebaseService';
import { toast } from 'react-toastify';
import { updateUserProfilePhoto } from '../../firestore/firestoreService';
import firebase from '../../config/firebase';

export default function PhotoUploadWidget({files, setFiles}) {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleUploadImage() {
    setLoading(true);
    const filename = cuid() + '.' + getFileExtension(files[0].name);
    const uploadTask = uploadToFirebaseStorage(image, filename);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    }, error => {
      toast.error(error.messege);
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          setLoading(false);
          handleCancelCrop();

      })
    })
  }

  function handleCancelCrop() {
    setFiles([]);
    setImage(null);
  }


  function handleAttachImage() {
    setFiles([]);
    setImage(null);
  }

  async function handleOCRImage() {
    const functionRef = firebase.app().functions("us-central1").httpsCallable('ocrScanText');
    const { data } = await functionRef({ filename: "019FB427-B9F1-428E-86DC-E609E5E644AC.jpeg" });
  }

  return (
    <Grid>

      <Grid.Column width={4}>
        <Header color='teal' sub content='Step 2 - Resize' />
        {files.length > 0 && (
          <>
          <PhotoWidgetCropper
            setImage={setImage}
            imagePreview={files[0].preview}
          />


            <Button
              onClick={handleUploadImage}
              style={{ borderLeft: 0, backgroundColor: "#fff", width: "100%" }}
              className='reviewCompletePrimaryButton'
            >
              Attach
            </Button>

            <Button
              onClick={handleOCRImage}
              style={{ borderLeft: 0, backgroundColor: "#fff", width: "100%" }}
              className='reviewCompletePrimaryButton'
            >
              Scan text
            </Button>     

            <Button
              onClick={handleOCRImage}
              style={{ borderLeft: 0, backgroundColor: "#fff", width: "100%" }}
              className='reviewCompletePrimaryButton'
            >
              Attach & scan text
            </Button>            
          </>

        )}
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={4}>
        <Header color='teal' sub content='Step 3 - Preview & upload' />
        {files.length > 0 && (
          <>
            <div
              className='img-preview'
              style={{ minHeight: 200, minWidth: 200, overflow: 'hidden' }}
            />
            <Button.Group>
              <Button loading={loading} onClick={handleUploadImage} style={{ width: 100 }} positive icon='check' />
              <Button disabled={loading} onClick={handleCancelCrop} style={{ width: 100 }} icon='close' />
            </Button.Group>
          </>
        )}
      </Grid.Column>
    </Grid>
  );
}
