import {
  SET_INCLUDE_PUBLIC,
  FETCH_REVIEWING_NOTES,
  SET_SEARCH_KEYS,
  SET_HITS
} from './searchConstants';
import {  getOverdueNotes } from '../../app/common/util/util';
import algoliasearch from 'algoliasearch/lite';

const initialState = {
  includePublic: false,
  userKey: null,
  sharingKey: null,
  client: null,
  publicClient: null,
  notes: [],
  hits: [],

};

export default function reviewReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_HITS:
      return {
        ...state,
        hits: payload,
      };
    case SET_INCLUDE_PUBLIC:
      return {
        ...state,
        includePublic: payload,
      };
      case SET_SEARCH_KEYS:
        return {
          ...state,
          userKey: payload.userKey,
          sharingKey: payload.sharingKey,
          client: algoliasearch(
            process.env.REACT_APP_ALGOLIA_ID,
            payload.userKey
          ),
          publicClient: algoliasearch(
            process.env.REACT_APP_ALGOLIA_ID,
            payload.sharingKey
          )
        };      
    case FETCH_REVIEWING_NOTES:
      
      let overdueNotes = getOverdueNotes(payload.topic, [...payload.notes]);

      return {
        ...state,
        notes: payload.notes,
        suggestedNotes: overdueNotes,
        numberOfNotes: (state.reviewType != "suggested" ? payload.notes.length : overdueNotes.length)
      };

    default:
      return state;
  }
}
