import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Image, Segment } from 'semantic-ui-react';

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

import firebase from '../../app/config/firebase';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';


export default function BillingPortalButton({ subtitle }) {
  const stripe = useStripe();
  const elements = useElements();

  const db = firebase.firestore();
  const user = firebase.auth().currentUser;

  const [loadingPortal, setLoadingPortal] = useState(false);


  async function billingPortal(event) {

    try {
      setLoadingPortal(true);
      const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeCreateBillingPortalLink');
      const { data } = await functionRef({ returnUrl: window.location.href });
      window.location.assign(data.url);
      setLoadingPortal(false);

    } catch (error) {
      toast.error(error.message);
      setLoadingPortal(false);

    }    
  }  

  return (

      <Button loading={true}
      onClick={billingPortal} loading={loadingPortal} style={{ borderLeft: 0, backgroundColor: "#fff", height: 50, width: 240}}
                className='reviewCompletePrimaryButton normalTextFont' content='SUBSCRIBE' />


  );
}

