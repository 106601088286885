import {
  CREATE_TOPIC,
  UPDATE_TOPIC,
  DELETE_TOPIC,
  FETCH_TOPICS,
  LISTEN_TO_TOPIC_CHAT,
  LISTEN_TO_SELECTED_TOPIC,
  CLEAR_TOPICS,
  RETAIN_STATE_TOPIC,
  CLEAR_SELECTED_TOPIC,
  SET_FILTER,
  CLEAR_JUST_REMOVED,
  SET_NEEDS_TOPIC_FETCH
} from './topicConstants';

const initialState = {
  needsTopicFetch: true,
  justAddedTopicId: null,
  justRemovedTopicId: null,
  userTotalNoteCount: 0,

  topics: [],
  moreTopics: true,
  selectedTopic: null,
  selectedTopicId: null,

  filter: "all",
  filterFolder: null
  // lastVisible: null,
  // filter: 'all',
  // startDate: new Date(),
  // retainState: false,
};

export default function topicReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_NEEDS_TOPIC_FETCH:
      return {
        ...state,
        needsTopicFetch: payload
      };

    case SET_FILTER:
      return {
        ...state,
        filter: payload.filter,
        filterFolder: payload.filterFolder
      };
    case CREATE_TOPIC:
      return {
        ...state,
        topics: [...state.topics, payload],
      };
    case UPDATE_TOPIC:
      return {
        ...state,
        topics: [
          ...state.topics.filter((evt) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_TOPIC:
      return {
        ...state,
        topics: [...state.topics.filter((evt) => evt.id !== payload)],
      };
    case FETCH_TOPICS:

      var newUserTotalNoteCount = 0

      payload.topics.forEach(topic => {
        if (!topic.example && !topic.how_to && !topic.shared_id && topic.note_count) {
          newUserTotalNoteCount = newUserTotalNoteCount + topic.note_count
        }
      });

      var justAddedTopicId = null;
      var justRemovedTopicId = null;
      if (!state.needsTopicFetch) {
        let oldTopicIds = state.topics.map(topic => (topic.shared_id ? topic.shared_id : topic.id));
        let newTopicIds = payload.topics.map(topic => (topic.shared_id ? topic.shared_id : topic.id));

        let addedTopicIds = newTopicIds.filter(topic => !(oldTopicIds.includes(topic)))
        let removedTopicIds = oldTopicIds.filter(topic => !(newTopicIds.includes(topic)))


        if (addedTopicIds.length > 0) {
          justAddedTopicId = addedTopicIds[0]
        }

        if (removedTopicIds.length > 0) {
          justRemovedTopicId = removedTopicIds[0]
        }

      }

      return {
        ...state,
        userTotalNoteCount: newUserTotalNoteCount,
        needsTopicFetch: false,
        justAddedTopic: justAddedTopicId,
        justRemovedTopicId: justRemovedTopicId,
        topics: [...payload.topics]//, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics, ...payload.topics],
      };
    case LISTEN_TO_TOPIC_CHAT:
      return {
        ...state,
        comments: payload,
      };
    case LISTEN_TO_SELECTED_TOPIC:

      state.topics.some(function (topic, index) {

        if (topic.id === payload.id) {
          state.topics[index] = payload;
          return true;
        }
        return false;
      });


      return {
        ...state,
        selectedTopic: payload,
        selectedTopicId: payload.id
      };
    case CLEAR_JUST_REMOVED:
      return {
        ...state,
        justRemovedTopicId: null,
      };
    case CLEAR_SELECTED_TOPIC:
      return {
        ...state,
        selectedTopic: null,
        selectedTopicId: null
      };
    case CLEAR_TOPICS:
      return {
        ...state,
        topics: [],
        moreTopics: true,
        lastVisible: null,
      };

    case RETAIN_STATE_TOPIC:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}

