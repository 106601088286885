import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { updateUserEmail } from '../../app/firestore/firebaseService';
import firebase from '../../app/config/firebase';
import { addFolderToUser, addFolderToTopic, renameFolderFromUser } from '../../app/firestore/firestoreService';
import { logEvent } from '../../app/common/logging/logging';

export default function CreateFolderModal({topicContext, oldFolderToRename, topicsInFolder, setFilterDropdownOpen}) {
    const dispatch = useDispatch();

    const { currentUserProfile } = useSelector((state) => state.profile);
    const { selectedTopic } = useSelector((state) => state.topic);

    return (
        <ModalWrapper size='mini' header="Enter the folder's new name">
            <Formik
                initialValues={{ folderName: oldFolderToRename }}
                validationSchema={Yup.object({
                    folderName: Yup.string().required()
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await renameFolderFromUser(currentUserProfile.id, oldFolderToRename, values.folderName, topicsInFolder)

                        dispatch(closeModal());

                        if (topicContext) {
                            dispatch(closeModal);
                            dispatch(openModal({ modalType: 'AddToFoldersModal', modalProps: { topicContext: true } }))
                        } else {
                            setFilterDropdownOpen(true);
                        }
                        
                        logEvent("Renamed Folder");

                    } catch (error) {

                        setErrors({ auth: error.message });

                        setSubmitting(false);
                    }
                }}
            >

                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className='ui form'>
                        <MyTextInput autoFocus name='folderName' placeholder={""} />
                        {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                        <Button className="submitButton"
                            style={{ color: "white", height: 44, borderRadius: 22 }}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Save'
                        />

                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    )
}