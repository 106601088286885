import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { logEvent } from "../../app/common/logging/logging";

export default function VisibilityModal() {

    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);


    async function changeVisibility(visibility) {

        let visibilityString

        if (visibility == "public") {
            visibilityString = "everyone."
        } else if (visibility == "with_link") {
            visibilityString = "anyone with the link."

        } else {
            visibilityString = "only you."

        }
        toast.info("This topic is now visible to " + visibilityString)

        dispatch(closeModal());
        await updateTopicAttributes(selectedTopic.id, { visibility: visibility })
        logEvent("Topic Updated Visibility", {visiblity: visibility, topic_id: selectedTopic.id, topic_name: selectedTopic.name})
        
    }



    return (
        <ModalWrapper
            size="tiny"
            header={
                <div className="titleTextFont" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 18, padding: 8 }}>
                    <div style={{}}>

                        Change visibility
                 </div>
                </div>
            }
            className='nopadding'
        >

            <div className="hoverableMenuItem" onClick={() => changeVisibility("private")}
                style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 15, padding: 12, borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                <div style={{}}>

                    Just me
            </div>
            </div>

            <div className="hoverableMenuItem" onClick={() => changeVisibility("with_link")}
                style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 15, padding: 12, borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                <div style={{}}>

                    Anyone with the link
            </div>
            </div>

            <div className="hoverableMenuItem" onClick={() => changeVisibility("public")}
                style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 15, padding: 12, borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                <div style={{}}>

                    Public
            </div>
            </div>

        </ModalWrapper>
    );
}
