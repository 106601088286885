import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import FolderRow from "./FolderRow";
import FoldersList from "./FoldersList";
import { logEvent } from "../../app/common/logging/logging";

export default function AddToFoldersModal({ }) {

  const dispatch = useDispatch();


  async function addFolder() {

    dispatch(closeModal());
    dispatch(openModal({ modalType: 'CreateFolderModal', modalProps: { topicContext: true } }))
    logEvent("Add Folder Tapped")
  }

 

  return (
    <ModalWrapper
      size="tiny"
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: 60 }}>

          </div>
          <div style={{ padding: 0, display: "flex", alignItems: "center", fontSize: 18 }}>
            Folders
          </div>
          <div style={{
            padding: 0, display: "flex", justifyContent: "flex-end", textAlign: "right", width: 60
          }}>
            <Button
              onClick={addFolder}
              circular
              style={{
                backgroundColor: "#FFF",
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0

              }}
            >
              <img
                src='/assets/addTopic.png'
                alt='logo'
                style={{ width: "auto", height: 20 }}
              />
            </Button>
          </div>
        </div>
      }
      className='editor'
      styles={{}}
    >
      <FoldersList topicContext={true}></FoldersList>

    </ModalWrapper>
  );
}
