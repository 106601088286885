import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup, Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { logEvent } from "../../app/common/logging/logging";

export default function SpacedRepetitionModal() {

    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);
    const { isSubscriber } = useSelector((state) => state.auth);


    async function changeSpacedRepetition(spacedRepetition) {

        if (!isSubscriber && spacedRepetition) {
      
            dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { subWall: true,
              subTitle: "Spaced repetition requires a Notefuel Premium subscription." } }))

            logEvent("Sub Wall Hit SR")
            return;

        }

        let spacedRepetitionString = spacedRepetition ? "on" : "off"

        toast.info("Spaced repetition is now turned " + spacedRepetitionString + " for this topic.")

        dispatch(closeModal());
        await updateTopicAttributes(selectedTopic.id, { auto_spaced_repetition: spacedRepetition })
        logEvent("Topic Updated SR", {auto_spaced_repetition: spacedRepetition})

    }



    return (
        <ModalWrapper
            size="tiny"
            header={
                <div style={{ display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{}}>

                        <Image
                            src='/assets/illustration2.png'
                            style={{ height: "auto", width: 120 }}
                        />

                    </div>
                    <div className="titleTextFont" style={{paddingTop: 16, fontSize: 18}}>
                        Spaced repetition
                    </div>
                    <div className="normalTextFont" style={{paddingTop: 16, fontSize: 14, color: "#3F3F3F", lineHeight: "1.4285em",
                fontWeight: "400", textAlign: "center"}}>
                        If you enable spaced repetition for a topic, Notefuel will remind you to review its notes precisely when it's optimal for information retention.
                    </div>                    
                </div>
            }
            className='nopadding'
            styles={{}}
        >

            <div className="hoverableMenuItem" onClick={() => changeSpacedRepetition(false)}
                style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 15,
                padding: 12, borderBottom: "1px solid rgba(34,36,38,.15)"}}>
                <div style={{}}>

                    Off
            </div>
            </div>

            <div className="hoverableMenuItem" onClick={() => changeSpacedRepetition(true)}
                style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 15, padding: 12, borderBottom: "1px solid rgba(34,36,38,.15)" }}>
                <div style={{}}>

                    On
            </div>
            </div>

        </ModalWrapper>
    );
}
