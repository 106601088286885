import React from 'react';
import Lottie from 'react-lottie'
import animationData from './logo_lottie.json'

export default function LogoLottie() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return (
        <Lottie options={defaultOptions}
              height={120}
              width={120}
              speed={2}
        />
    )
}