import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Segment,
  Menu,
  Checkbox,
  Item,
  Button,
  Select,
  Grid,
} from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import {
  setAutoflashcardsEnabled,
  setRandomOrder,
  setNumberOfNotes,
  setReviewType,
  setStage,
  setSelectedNotes,
  restartReviewAgain,
  restartReviewForgotten,
} from "./reviewActions";
import {
  getNextSuggestedReview,
  shuffleArray,
  getNextReviewDateString,
} from "../../app/common/util/util";
import { format } from "date-fns";
import ReviewHistoryGraph from "./ReviewHistoryGraph";
import { logEvent } from "../../app/common/logging/logging";

export default function ReviewCompleteView() {
  const dispatch = useDispatch();

  const { selectedTopic } = useSelector((state) => state.topic);
  const {
    reviewType,
    numberOfNotes,
    notes,
    selectedNotes,
    randomOrder,
  } = useSelector((state) => state.review);

  async function reviewForgotten() {
    dispatch(restartReviewForgotten());
    logEvent("Review Forgotten Tapped")

  }

  async function again() {
    dispatch(restartReviewAgain());
    logEvent("Refuel Again Tapped")

  }

  async function shared() {


    logEvent("Refuel Share Tapped")

  }

  var rememberedNotes = 0;
  var forgottenNotes = 0;

  for (var i = 0; i < selectedNotes.length; i++) {
    if (selectedNotes[i].recalled_during_review) {
      rememberedNotes = rememberedNotes + 1;
    } else {
      forgottenNotes = forgottenNotes + 1;
    }
  }

  const hideAgainButton = (rememberedNotes + forgottenNotes) == 0;
  const hideReviewForgottenButton = forgottenNotes == 0;

  let subtitleText
  let titleText

  if (reviewType == "suggested") {

    if (forgottenNotes == 0) {

      titleText = "Amazing!";
      subtitleText = "You're done reviewing these notes for now.";

    } else {
      titleText = "Not bad!";
      subtitleText =  "We suggest you review your forgotten notes again immediately.";    
    }

  } else {

    titleText = "Nice job!";
    subtitleText =  "You've completed this review.";

  }

  var nextReviewDate = getNextReviewDateString(selectedTopic)

  return (
    <Grid columns={16} centered style={{}}>
      <Grid.Row centered>
        <Grid.Column width={16}>
          <Grid
            verticalAlign='middle'
            columns={16}
            centered
            //   style={{ backgroundColor: "#FFF" }}
            //   className='customCard'
          >
            <Grid.Row centered>
              <Grid.Column
                width={16}
                className="titleTextFont"
                style={{
                  fontSize: 24,
                  textAlign: "center",
                }}
              >
                {titleText}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered style={{ paddingTop: 0 }}>
              <Grid.Column
                width={16}
                style={{ fontSize: 16, textAlign: "center" }}
              >
                                {subtitleText}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column width={16} className={"constrainedColumn"}>
              <div className="topicStatsWrapper" style={{ width: "100%", padding: 16}}>
                <div className="topicStatsList normalTextFont" style={{position: "relative", width: "100%"}}>
                    <div className='topicStat'>
                      <div className='topicStatKey'>Recalled</div>
                      <div className='topicStatValue titleTextFont'>{rememberedNotes} of {rememberedNotes + forgottenNotes}</div>
                    </div>
                    <div className='topicStat'>
                      <div className='topicStatKey'>Recall rate</div>
                      <div className='topicStatValue titleTextFont'>{Math.round(100 * rememberedNotes / (rememberedNotes + forgottenNotes))}%</div>
                    </div>
                    <div className='topicStat'>
                      <div className='topicStatKey'>Next review</div>
                      <div className='topicStatValue titleTextFont'>{nextReviewDate}</div>
                    </div>
                    
                    <ReviewHistoryGraph postReview={true} ></ReviewHistoryGraph>

                  </div>
                </div>


              </Grid.Column>
            </Grid.Row>


            {!hideReviewForgottenButton && (

            <Grid.Row centered style={{ paddingTop: 0 }}>
              <Grid.Column
                width={16}
                style={{ fontSize: 16, textAlign: "center" }}
              >
                <Button
                  onClick={reviewForgotten}
                  style={{ borderLeft: 0, backgroundColor: "#fff" }}
                  className='reviewCompletePrimaryButton normalTextFont'
                >
                  Review forgotten notes
                </Button>
              </Grid.Column>
            </Grid.Row>
            )}

            {!hideAgainButton && (
              <Grid.Row centered style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid.Column
                  width={16}
                  style={{ fontSize: 16, textAlign: "center" }}
                >
                  <Button
                    onClick={again}
                    style={{
                      backgroundColor: "#FAFAFA",
                      color: "#3F3F3F",
                      height: 40,
                    }}
                    className='reviewCompleteSecondaryButton'
                  >
                    <span>
                      <img
                        src='/assets/undo.png'
                        style={{
                          height: 18,
                          width: "auto",
                          paddingTop: 3,
                          paddingRight: 10,
                          backgroundColor: "#fff",
                        }}
                      />
                    </span>
                    <span>Again</span>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            )}

            {/* <Grid.Row centered style={{ paddingTop: 0 }}>
              <Grid.Column
                width={8}
                style={{ fontSize: 16, textAlign: "center" }}
              >
                <Button
                  onClick={shared}
                  style={{
                    backgroundColor: "#FAFAFA",
                    color: "#3F3F3F",
                    height: 40,
                  }}
                  className='reviewCompleteSecondaryButton'
                >
                  <span>
                    <img
                      src='/assets/share.png'
                      style={{
                        height: 21,
                        width: "auto",
                        paddingTop: 5,
                        paddingRight: 10,
                        backgroundColor: "#fff",
                      }}
                    />
                  </span>
                  <span>Share</span>
                </Button>
              </Grid.Column>
            </Grid.Row> */}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
