import React, { useState } from 'react';
import { Grid, Header, Button, Dimmer, Loader } from 'semantic-ui-react';
import ImageEditorCropper from './ImageEditorCropper';
import cuid from 'cuid';
import { getFileExtension } from '../../app/common/util/util';
import { uploadToFirebaseStorage } from '../../app/firestore/firebaseService';
import { toast } from 'react-toastify';
import { updateUserProfilePhoto } from '../../app/firestore/firestoreService';
import firebase from '../../app/config/firebase';
import { logEvent, logError } from '../../app/common/logging/logging';
import {Detector} from "react-detect-offline";

export default function ImageEditor({ files, setFiles, setUploadedImage, setUploadedImageDimensions, setImageDownloadURL ,
  setOcrLoading, ocrNewImage, setImageAttachmentLoading, stopTextScanIfNecessary, handleOfflineMessage}) {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState("");
    
    function handleAttach() {
        uploadImage(false)
        logEvent("Camera Attachment Button Tapped")

    }

    function handleTextScan() {
      logEvent("Camera Scan Button Tapped")

      if (stopTextScanIfNecessary()) {
        return;
      }
        uploadImage(true)
    }



    function uploadImage(forScan) {

        const filename = cuid() + '.' + getFileExtension(files[0].name);
        const uploadTask = uploadToFirebaseStorage(image, filename);

        if (forScan) {
            setOcrLoading(true);
            setFiles([]);
            setImage(null);
        } else {
            setImageAttachmentLoading(true);
            setFiles([]);
            setImage(null);
        }


        uploadTask.on('state_changed', snapshot => {

        }, error => {
            toast.error(error.messege);
            logError(error)

        }, () => {

            if (forScan) {
                ocrNewImage(uploadTask.snapshot.ref.name)
            } else {

                setFiles([]);
                setImage(null);
                setImageDownloadURL("");
                setUploadedImageDimensions(null);
                let uploadedImage = {bucket: uploadTask.snapshot.ref.bucket, fullPath: uploadTask.snapshot.ref.fullPath, filename: uploadTask.snapshot.ref.name}
                setUploadedImage(uploadedImage);
                setImageAttachmentLoading(false);

            }



        })
    }

    function handleCancel() {
        setFiles([]);
        setImage(null);
        logEvent("Camera Close Tapped")

      }


    return (
<Detector
  render={({ online }) => (
    <>
            <Grid.Row centered style={{backgroundColor: "#fff", borderBottom: "1px solid #F1F1F1" }}>

            <Grid.Column width={12}>

            {loading &&
                <Dimmer active style={{ borderRadius: 10, opacity: 0.85 }}>
                    <Loader>{loading}</Loader>
                </Dimmer>
            }

                {files.length > 0 && (
                    <>
                        <ImageEditorCropper
                            setImage={setImage}
                            imagePreview={files[0].preview}
                        />


                    </>

                )}
            </Grid.Column>
            </Grid.Row>
   

            <Grid.Row centered style={{backgroundColor: "#fff", borderRadius: 10}}>
              <Grid.Column width={12} style={{}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >

                  <Button
                    onClick={handleCancel}
                    circular
                    style={{
                      backgroundColor: "#FFF",
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 0,
                      paddingLeft: 0
                    }}
                  >
            <img src='/assets/leftArrow.png' alt='logo' style={{ width: 21, height: "auto", paddingTop: 10, paddingBottom: 5, paddingRight: 10 }} />

                    <span className="hideOnMobile">Cancel</span>
                  </Button>

                </div>
              </Grid.Column>
              <Grid.Column width={4} style={{}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                                    <Button
                    onClick={online ? handleAttach : handleOfflineMessage}
                    
                    style={{
                      backgroundColor: "#F7F7F7",
                      height: 40,
                      borderRadius: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      whiteSpace: "nowrap"

                    }}
                  >
                    Attach <span className="hideOnMobile">&nbsp;image</span>

                  </Button>

                  <Button
                    onClick={online ? handleTextScan : handleOfflineMessage}
                    
                    style={{
                      backgroundColor: "#F7F7F7",
                      height: 40,
                      borderRadius: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 8,
                      whiteSpace: "nowrap"

                    }}
                  >
                    Scan text
                  </Button>
                </div>
              </Grid.Column>{" "}
            </Grid.Row>
</>
  )}
        />
    );
}
