import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import FolderRow from "./FolderRow";
import { set } from "immutable";
import { setFilter } from "../topics/topicActions";
import { logEvent } from "../../app/common/logging/logging";

export default function FoldersList({topicContext, filterDropdownOpen, setFilterDropdownOpen }) {

    const dispatch = useDispatch();

    const { currentUserProfile } = useSelector((state) => state.profile);
    const { selectedTopic, topics } = useSelector((state) => state.topic);

    const userFolders = currentUserProfile && currentUserProfile.folders ? currentUserProfile.folders : []
    const topicFolders = selectedTopic && selectedTopic.folders ? selectedTopic.folders : []

    async function folderTapped(folder) {
        if (topicContext) {
            toggleFolder(folder)
        } else {
            setTopicFilter(folder)
        }
        
        logEvent("Folder Tapped");
    }

    async function toggleFolder(folder) {

        if (topicFolders.includes(folder)) {
            await removeFolderFromTopic(selectedTopic.id, folder)
        } else {
            await addFolderToTopic(selectedTopic.id, folder)
        }
    }

    async function setTopicFilter(folder) {
        setFilterDropdownOpen(false);
        dispatch(setFilter( "folder",  folder));

    }

    return (

        <div style={{ maxHeight: "50vh", overflowY: "auto", fontSize: 14, paddingTop: 8, paddingBottom: 8 }}>
            {userFolders.length == 0 ?
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center", padding: 6, fontSize: 14, color: "#6C6C6C" }}>
                    No folders yet.
                </div>

                :

                userFolders.sort().map((folder, index) => (
                    <FolderRow key={index} filterDropdownOpen={filterDropdownOpen} setFilterDropdownOpen={setFilterDropdownOpen} topicContext={topicContext} uid={currentUserProfile.id} folder={folder} topicFolders={topicFolders} topicsInFolder={topics.filter(topic => topic.folders && topic.folders.includes(folder))} clickHandler={() => topicContext ? toggleFolder(folder) : setTopicFilter(folder)}
                    />

                ))

            }
        </div>

    );
}
