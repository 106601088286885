
// export const LISTEN_TO_REVIEWING_TOPIC = 'LISTEN_TO_REVIEWING_TOPIC';
// export const CLEAR_REVIEWING_TOPIC = 'CLEAR_REVIEWING_TOPIC';
export const SET_AUTOFLASHCARDS = 'SET_AUTOFLASHCARDS';
export const SET_REVIEW_TYPE = 'SET_REVIEW_TYPE';
export const SET_RANDOM_ORDER = 'SET_RANDOM_ORDER';
export const SET_NUMBER_OF_NOTES = 'SET_NUMBER_OF_NOTES';
export const FETCH_REVIEWING_NOTES = 'FETCH_REVIEWING_NOTES';
export const UPDATE_SELECTED_NOTE = 'UPDATE_SELECTED_NOTE';
export const UNDO_GRADE = 'UNDO_GRADE';
export const SET_SHOW_CURRENT_ANSWER = 'SET_SHOW_CURRENT_ANSWER';
export const SET_STAGE = 'SET_STAGE';
export const SET_CURRENT_NOTE_INDEX = 'SET_CURRENT_NOTE_INDEX';
export const SET_SELECTED_NOTES = 'SET_SELECTED_NOTES';
export const SET_REVIEW_INITIAL_STATE = 'SET_REVIEW_INITIAL_STATE';
export const FLIP_CARD = 'FLIP_CARD';
export const NEXT_CARD = 'NEXT_CARD';
export const PREVIOUS_CARD = 'PREVIOUS_CARD';
export const RESTART_REVIEW_AGAIN = 'RESTART_REVIEW_AGAIN';
export const RESTART_REVIEW_FORGOTTEN = 'RESTART_REVIEW_FORGOTTEN';


