import React from 'react';

import { Container } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import PhotoUploadWidget from '../../app/common/photos/PhotoUploadWidget';
import NoteEditor from './NoteEditor';


export default function EditorWrapper() {

    const { sidebarVisible } = useSelector(state => state.sidebar);
    const { selectedTopic } = useSelector(state => state.topic);


    const wrapperStyle = (selectedTopic && selectedTopic.shared_id) ? 
        {
            width: "100%", backgroundColor: "#FFFFFF", boxShadow: "0 10px 30px 0 rgba(0,0,0,0.05)"
        } :
        {
            width: "100%"
        }


    return (

        <div style={wrapperStyle}>

            <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{ fontSize: 16, paddingTop: 0, paddingBottom: 16 }}>

                {(selectedTopic && selectedTopic.shared_id) ?

                    <div style={{paddingTop: 16}}>
                        <span style={{fontSize: 15}}>Shared with you</span> · <span className="italicTextFont" style={{  fontSize: 15 }}>View only</span>
                    </div>

                    :

                    <NoteEditor></NoteEditor>
                }
            </Container>

        </div>
    )
}

